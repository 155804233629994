@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  /* font-family: "Montserrat", sans-serif; */
  /* font-family: "Roboto Mono", monospace; */
  font-family: "Mukta", sans-serif;
  background-color: #f2f4f8;
  margin: 0;
  box-sizing: border-box;
}

nav {
  width: 100%;
  display: flex;
  padding: 5px 15px;
  align-items: flex-start;
  justify-content: space-between;
  margin: auto;
  margin-bottom: 10px;
}
.ratingFeature {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ratingRadio {
  display: none;
}
.star {
  cursor: pointer;
  transition: color 200ms;
}
.box {
  width: 100%;
}
.mobileNav {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}
.content {
  min-height: calc(100vh - 68px);
}
.Foot {
  position: relative;
  left: 0;
  /* right: 0; */
  bottom: 0;
}
.eth {
  display: inline;
  height: 20px;
  margin: auto;
}
.logoContact2 {
  display: flex;
  height: 32px;
  margin: auto;
  margin-top: 5px;
  padding: 2px;
}
.logoContact1 {
  display: flex;
  height: 43px;
  margin: auto;
  padding: 2px;
}
.logoContact3 {
  display: flex;
  height: 29px;
  margin: auto;
  margin-top: 5px;
  padding: 2px;
  padding-left: 12px;
}
.homeHeader2 {
  text-align: center;
}
.homeHeader {
  /* font-family: "Montserrat", sans-serif; */
  /* font-family: "Roboto Mono", monospace; */
  font-family: "Mukta", sans-serif;
  font-weight: 900;
  color: rgba(43, 62, 135, 1);
  align-items: center;
  margin: auto;
  padding: 10px;
  justify-items: center;
  display: flex;
}
.infoContent {
  /* font-family: "Montserrat", sans-serif; */
  /* font-family: "Roboto Mono", monospace; */
  font-family: "Mukta", sans-serif;
  font-weight: 500;
  color: rgba(104, 104, 104, 1);
}
.infoHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-family: "Montserrat", sans-serif; */
  /* font-family: "Roboto Mono", monospace; */
  font-family: "Mukta", sans-serif;
  font-weight: 700;
  color: rgba(43, 62, 135, 1);
}
.infoHeader2 {
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-family: "Montserrat", sans-serif; */
  /* font-family: "Roboto Mono", monospace; */
  font-family: "Mukta", sans-serif;
  font-weight: 700;
  color: black;
}
.infoHeader3 {
  display: flex;
  justify-content: right;
  margin-right: 150px;
  align-items: center;
  /* font-family: "Montserrat", sans-serif; */
  /* font-family: "Roboto Mono", monospace; */
  font-family: "Mukta", sans-serif;
  font-weight: 700;
  color: rgba(43, 62, 135, 1);
}
.icon {
  height: 150px;
  width: 150px;
  display: flex;
}
.nav2 {
  display: flex;
  margin: 0px 0px 0px;
  align-items: flex-end;
  justify-content: right;
}
.logo {
  float: left;
  width: 250px;
  height: max-content;
  padding-top: 10px;
}
.formLabel {
  /* font-family: "Montserrat", sans-serif; */
  /* font-family: "Roboto Mono", monospace; */
  font-family: "Mukta", sans-serif;
  font-weight: 500;
  padding-right: 25px;
}
.formLabel2 {
  /* font-family: "Montserrat", sans-serif; */
  /* font-family: "Roboto Mono", monospace; */
  font-family: "Mukta", sans-serif;
  font-weight: 500;
  padding-right: 45px;
}
.formLabel3 {
  /* font-family: "Montserrat", sans-serif; */
  /* font-family: "Roboto Mono", monospace; */
  font-family: "Mukta", sans-serif;
  font-weight: 500;
  padding-right: 43px;
  padding-top: 5px;
  justify-content: center;
}
.formLabel4 {
  /* font-family: "Montserrat", sans-serif; */
  /* font-family: "Roboto Mono", monospace; */
  font-family: "Mukta", sans-serif;
  font-weight: 500;
  padding-right: 36px;
  padding-left: 36px;
  justify-content: center;
}
.formLabel5 {
  /* font-family: "Montserrat", sans-serif; */
  /* font-family: "Roboto Mono", monospace; */
  font-family: "Mukta", sans-serif;
  font-weight: 500;
  padding-right: 20px;
  justify-content: center;
}
.formLabel6 {
  /* font-family: "Montserrat", sans-serif; */
  /* font-family: "Roboto Mono", monospace; */
  font-family: "Mukta", sans-serif;
  font-weight: 500;
  padding-right: 33px;
  padding-left: 33px;
  justify-content: center;
}
.formLabel7 {
  /* font-family: "Montserrat", sans-serif; */
  /* font-family: "Roboto Mono", monospace; */
  font-family: "Mukta", sans-serif;
  font-weight: 500;
  padding-right: 24px;
  padding-left: 23px;
  justify-content: center;
}
.formLabel8 {
  /* font-family: "Montserrat", sans-serif; */
  /* font-family: "Roboto Mono", monospace; */
  font-family: "Mukta", sans-serif;
  font-weight: 500;
  padding-right: 30px;
  padding-left: 29px;
  justify-content: center;
}

.formLabel9 {
  /* font-family: "Montserrat", sans-serif; */
  /* font-family: "Roboto Mono", monospace; */
  font-family: "Mukta", sans-serif;
  font-weight: 500;
  padding-right: 21px;
  padding-left: 18px;
  justify-content: center;
}

.formLabel10 {
  /* font-family: "Montserrat", sans-serif; */
  /* font-family: "Roboto Mono", monospace; */
  font-family: "Mukta", sans-serif;
  font-weight: 500;
  padding-right: 5px;
  padding-left: 5px;
  justify-content: center;
}

.pageHeader {
  margin: auto;
  width: 35%;
  padding: 10px;
  justify-items: center;
  /* font-family: "Montserrat", sans-serif; */
  /* font-family: "Roboto Mono", monospace; */
  font-family: "Mukta", sans-serif;
  font-weight: 700;
}

.smallHeader {
  /* font-family: "Montserrat", sans-serif; */
  /* font-family: "Roboto Mono", monospace; */
  font-family: "Mukta", sans-serif;
  font-weight: 700;
  margin: auto;
  color: rgba(43, 62, 135, 1);
}
.skills {
  /* font-family: "Montserrat", sans-serif; */
  /* font-family: "Roboto Mono", monospace; */
  font-family: "Mukta", sans-serif;
  font-weight: 500;

  color: rgba(43, 62, 135, 1);
}
.nameInput,
.categoryInput,
.descriptionInput,
.unitInput,
.priceInput {
  box-shadow: rgba(0, 0, 0, 0.99);
  border: shadow;
  background: #ffffff;
  border-radius: 3rem;
  height: 3rem;
  width: 100%;
  outline: solid;
  /* font-family: "Montserrat", sans-serif; */
  /* font-family: "Roboto Mono", monospace; */
  font-family: "Mukta", sans-serif;
  padding: 0 3rem;
  font-size: 1rem;
}
.formContainer {
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 425px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 10px 20px 0 rgba(#999, 0.25);
  padding: 0.75rem;
}
.navRight {
  position: absolute;
  right: 10px;
}

.btnConnect {
  position: relative;
  width: 130px;
  height: 50.75px;
  right: 0%;
  top: 0px;
  background: #35aef1;
  border-radius: 37px;
  /* font-family: "Montserrat", sans-serif; */
  /* font-family: "Roboto Mono", monospace; */
  font-family: "Mukta", sans-serif;
  font-weight: 700;
}

.btnBuy {
  position: relative;
  width: 150px;
  height: 50.75px;
  right: 0%;
  top: 0px;
  background: #35aef1;
  border-radius: 37px;
  /* font-family: "Montserrat", sans-serif; */
  /* font-family: "Roboto Mono", monospace; */
  font-family: "Mukta", sans-serif;
  font-weight: 700;
}

.navCreate {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  position: flex;
  width: 56px;
  height: 26px;

  top: calc(50% - 26px / 2 - 2px);
  /* font-family: "Montserrat", sans-serif; */
  /* font-family: "Roboto Mono", monospace; */
  font-family: "Mukta", sans-serif;
  font-weight: 700;
  color: rgba(43, 62, 135, 1);
}

.navShop {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;

  position: flex;
  max-width: 56px;
  height: 26px;
  left: calc(50% - 56px / 2 + 241.5px);
  top: calc(50% - 26px / 2 - 1px);
  /* font-family: "Montserrat", sans-serif; */
  /* font-family: "Roboto Mono", monospace; */
  font-weight: 900;
  color: rgba(43, 62, 135, 1);
}

.categoryImg {
  min-height: 115px;
  height: 15vw;
  width: 100%;
  border-radius: 1.5rem;
  object-fit: cover;
  margin: 0 auto;
}
.exploreCategories {
  display: flex;
  justify-content: space-between;
}
.exploreCategories a {
  width: 48%;
}
.accountIcon {
  display: flex;
  width: 50px;
  height: fit-content;
  padding-right: 5px;
}
.image {
  width: fit-content;
  resize: contain;
  height: 25%;
}

.itemImg {
  max-width: 160px;
  max-height: 160px;
  min-height: 160px;
  min-width: 160px;
}

.btnBuyHome {
  position: relative;
  bottom: 0px;
  justify-content: center;
  margin-bottom: 0px;
}

.productImg {
  max-width: 50%;
}

.compBtn {
  color: rgb(39, 196, 39);
  font-weight: 500;
  width: fit-content;
}
.canBtn {
  color: rgb(199, 33, 11);
  font-weight: 500;
  width: fit-content;
}
.disputeBtn {
  color: rgb(219, 206, 16);
  font-weight: 500;
  width: fit-content;
}

.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100px;
  height: 30px;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
}

.react-switch-label .react-switch-button {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 45px;
  height: 26px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
  width: 20px;
}
